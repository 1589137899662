
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        















































.objective {
  display: grid;
  grid-gap: 0.25rem;
  grid-template-rows: auto auto;
  grid-template-columns: 2rem 1fr auto span(1, 0, span(6));
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: black;
  text-decoration: none;

  &.expanded {
    grid-template-columns: 2rem 1fr auto span(2, 0, span(6));
    margin-top: 3rem;
    margin-bottom: 0.5rem;
  }
}

.objective__icon {
  grid-column: 1;
  align-self: start;
  padding-top: 0.15rem;
}

.objective__name {
  grid-column: 2;
  font-weight: 500;
}

.objective__progression-text {
  grid-column: 3;
  margin-right: 1rem;
  font-size: 0.8rem;
  text-align: right;
}

.objective__progression {
  grid-column: 4;
}

.objective__description {
  grid-row: 2;
  grid-column: 2 / 3;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
}
